<template>
  <div
    class="
      dis_flex
      flex_dir_col
      bg_col_fff
      bor_rad_4
      box_sha_0_0_8_8_black_80_per
      m_0_16
    "
  >
    <div class="font_16 font_bold m_0_12 m_t_16">数据总览</div>

    <div
      class="
        dis_grid
        grid_tem_col_rep_2_1fr grid_row_gap_16 grid_col_gap_8
        m_0_12 m_t_16
      "
    >
      <!-- 项 -->
      <div
        v-for="(item, index) in [
          {
            label: '累计进件',
            iconUrl:
              'http://songcw-sit.oss-cn-shanghai.aliyuncs.com/car-agent/20221111/hxmapTBjVPWamtGIwqmk.png',
            values: [
              {
                value: columnAmount(form.applyCount, {
                  fixed: 0,
                }),
                unit: '笔',
              },
              {
                value: columnAmount(form.applyAmount / form.applyCount / 10000),
                unit: '万元',
              },
            ],
          },

          {
            label: '累计批复',
            iconUrl:
              'http://songcw-sit.oss-cn-shanghai.aliyuncs.com/car-agent/20221111/RoFTWEigFdXotwfEBhrr.png',
            values: [
              {
                value: columnAmount(form.approvaledCount, {
                  fixed: 0,
                }),
                unit: '笔',
              },
              {
                value: columnAmount(form.approvaledAmount / 10000),
                unit: '万元',
              },
            ],
          },

          {
            label: '累计放款',
            iconUrl:
              'http://songcw-sit.oss-cn-shanghai.aliyuncs.com/car-agent/20221111/ctsUlBZknXXHmFydCTUE.png',
            values: [
              {
                value: columnAmount(form.loanedCount, {
                  fixed: 0,
                }),
                unit: '笔',
              },
              {
                value: columnAmount(form.loanedAmount / 10000),
                unit: '万元',
              },
            ],
          },

          //
        ]"
        :key="index"
        class="pos_rel dis_flex flex_dir_col bg_col_eef7ff bor_rad_4"
      >
        <div class="font_14 font_bold l_h_100_per m_0_12 m_t_12">
          {{ item.label }}
        </div>

        <div
          v-for="(item1, index1) in item.values"
          :key="index1"
          class="pos_rel z_1 dis_flex l_h_100_per m_0_12 m_t_12"
        >
          <div class="font_18 font_bold">{{ item1.value }}</div>
          <div class="font_10 col_999 m_t_4">{{ item1.unit }}</div>
        </div>

        <div class="h_12"></div>

        <!-- 右下 图标 -->
        <img class="pos_abs r_0 b_0 w_40" :src="item.iconUrl" />

        <!--  -->
      </div>

      <!--  -->
    </div>

    <div class="h_16"></div>

    <!--  -->
  </div>
</template>

<script>
import { columnAmount } from '@/utils/tools';

import { brokerDashboardLoanGeneralStatisticsPost } from '@/api/index';

export default {
  components: {},
  data() {
    return {
      form: {},

      //
    };
  },
  computed: {},
  mounted() {
    this.init();

    //
  },
  methods: {
    columnAmount,

    // 获取
    async getDetail() {
      this.form = await brokerDashboardLoanGeneralStatisticsPost();
    },

    // 初始化
    async init() {
      await this.getDetail();

      //
    },

    //
  },
};
</script>
