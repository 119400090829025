var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dis_flex flex_dir_col bg_col_fff bor_rad_4 box_sha_0_0_8_8_black_80_per m_0_16"},[_c('div',{staticClass:"font_16 font_bold m_0_12 m_t_16"},[_vm._v("数据总览")]),_c('div',{staticClass:"dis_grid grid_tem_col_rep_2_1fr grid_row_gap_16 grid_col_gap_8 m_0_12 m_t_16"},_vm._l(([
        {
          label: '累计进件',
          iconUrl:
            'http://songcw-sit.oss-cn-shanghai.aliyuncs.com/car-agent/20221111/hxmapTBjVPWamtGIwqmk.png',
          values: [
            {
              value: _vm.columnAmount(_vm.form.applyCount, {
                fixed: 0,
              }),
              unit: '笔',
            },
            {
              value: _vm.columnAmount(_vm.form.applyAmount / _vm.form.applyCount / 10000),
              unit: '万元',
            },
          ],
        },

        {
          label: '累计批复',
          iconUrl:
            'http://songcw-sit.oss-cn-shanghai.aliyuncs.com/car-agent/20221111/RoFTWEigFdXotwfEBhrr.png',
          values: [
            {
              value: _vm.columnAmount(_vm.form.approvaledCount, {
                fixed: 0,
              }),
              unit: '笔',
            },
            {
              value: _vm.columnAmount(_vm.form.approvaledAmount / 10000),
              unit: '万元',
            },
          ],
        },

        {
          label: '累计放款',
          iconUrl:
            'http://songcw-sit.oss-cn-shanghai.aliyuncs.com/car-agent/20221111/ctsUlBZknXXHmFydCTUE.png',
          values: [
            {
              value: _vm.columnAmount(_vm.form.loanedCount, {
                fixed: 0,
              }),
              unit: '笔',
            },
            {
              value: _vm.columnAmount(_vm.form.loanedAmount / 10000),
              unit: '万元',
            },
          ],
        },

        //
      ]),function(item,index){return _c('div',{key:index,staticClass:"pos_rel dis_flex flex_dir_col bg_col_eef7ff bor_rad_4"},[_c('div',{staticClass:"font_14 font_bold l_h_100_per m_0_12 m_t_12"},[_vm._v(" "+_vm._s(item.label)+" ")]),_vm._l((item.values),function(item1,index1){return _c('div',{key:index1,staticClass:"pos_rel z_1 dis_flex l_h_100_per m_0_12 m_t_12"},[_c('div',{staticClass:"font_18 font_bold"},[_vm._v(_vm._s(item1.value))]),_c('div',{staticClass:"font_10 col_999 m_t_4"},[_vm._v(_vm._s(item1.unit))])])}),_c('div',{staticClass:"h_12"}),_c('img',{staticClass:"pos_abs r_0 b_0 w_40",attrs:{"src":item.iconUrl}})],2)}),0),_c('div',{staticClass:"h_16"})])
}
var staticRenderFns = []

export { render, staticRenderFns }